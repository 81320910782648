import { CompRouterService, SharedService } from 'app/helpers/services';
import { UtilsService } from './../../../../helpers/services/core/utils.service';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-thor-get-template',
  templateUrl: './thor-get-template.component.html',
  styleUrls: ['./thor-get-template.component.scss']
})
export class ThorGetTemplateComponent implements OnInit {
  @Input() data: any;
  @Input() chatIndex: any;
  skillsData = [
    { name: 'Adobe Creative Suite', rating: 5 },
    { name: 'Adobe XD', rating: 4 },
    { name: 'Bootstrap', rating: 4 },
    { name: 'CSS', rating: 3 },
    { name: 'Figma', rating: 1 }
  ];

  trainingData = [
    {
      id: '1',
      title: 'Introduction to Spring Boot',
      date: '2024-07-26',
      time: '11:00 AM',
      trainerFirstName: 'Jesna',
      trainerLastName: 'Thejaswini',
      location: 'offline'
    },
    {
      id: '2',
      title: 'Introduction to Design Thinking',
      date: '2024-05-24',
      time: '11:00 AM',
      trainerFirstName: 'Sowbhagya',
      trainerLastName: 'Thantri',
      location: 'online'
    },
    {
      id: '3',
      title: 'What is Mobile User Experience?',
      date: '2024-01-01',
      time: '11:00 AM',
      trainerFirstName: 'Harshit',
      trainerLastName: 'Rao',
      location: 'online'
    }
  ];

  projectsAllocated = [
    { name: 'Tarento One', monthYear: 'Nov - 2022', totalHours: 150, spentHours: 136 },
    { name: 'Kronos', monthYear: 'Nov - 2022', totalHours: 80, spentHours: 32 }
  ];

  compliance = {
    "reportedDays": 50,
    "nonCompliance": 20
  };

  userProfile = {
    imagePath: 'https://via.placeholder.com/150', // Placeholder image
    firstName: 'Naveen',
    lastName: 'Prasath',
    email: 'naveen.prasath@tarento.com',
    dob: '05-11-1997',
    phone: '8940416286',
    designation: 'Senior Design Engineer',
    consultantProfileUrl: 'https://example.com',
    doj: '05-11-1997',
    tenure: '3 Years'
  };

  projects;
  selectedUser:any;

  constructor(private utils: UtilsService, private compRouter: CompRouterService,
    private sharedService: SharedService) {
  }

  ngOnInit() {
    this.projects = this.utils.getItem('masterData').projectList;
  }

  getStrokeDashArray(rating: number): string {
    const percentage = (rating / 5) * 100;
    const circumference = 2 * Math.PI * 15; // 2πr where r=15
    const progress = (percentage / 100) * circumference;
    return `${progress} ${circumference}`;
  }

  getRatingClass(rating: number): string {
    if (rating >= 4) {
      return 'green'; // High ratings
    } else if (rating >= 3) {
      return 'yellow'; // Medium ratings
    } else {
      return 'red'; // Low ratings
    }
  }


  getTrainerName(training: any): string {
    return `${training.trainerFirstName} ${training.trainerLastName}`;
  }

  getLocationLabel(location: string): string {
    return location === 'online' ? '🌐 Online' : '🏢 Offline';
  }

  getDay(date: string): string {
    const d = new Date(date);
    const day = d.getDate().toString();
    return day.length === 1 ? '0' + day : day; // Pad with '0' if single digit
  }


  getMonth(date: string): string {
    const d = new Date(date);
    return d.toLocaleString('default', { month: 'short' });
  }

  getProgressColor(spentHours: number, totalHours: number): string {
    const progress = spentHours / totalHours;

    if (progress <= 0.5) {
      return '#F44336';
    } else if (progress <= 0.75) {
      return '#FFC107';
    } else {
      return '#4CAF50';
    }
  }

  getCompliaceForCurrentYears(complianceData) {
    const currentYear = new Date().getFullYear();

    if (complianceData[currentYear]) {
      return { [currentYear]: complianceData[currentYear] };
    }

    return null;
  }

  formatMonthAndYear(dateString: string): string {
    const [year, month] = dateString.split('-');

    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];

    const monthName = monthNames[parseInt(month, 10) - 1];

    return `${monthName} ${year}`;
  }

  getProjectNameById(id: number): string | null {
    const project = this.projects.find(item => item.id === id);
    return project ? project.name : null;
  }

  getRedirectURL(action: string) {
    switch (action) {
      case 'My Profile':
        this.sharedService.routerObj = 'profile';
        this.compRouter.routeToContextUrls('profile');
        break;
      case 'My Skills':
        this.sharedService.routerObj = 'skills';
        this.compRouter.routeToContextUrls('profile');
        break;
      case 'My Trainings':
        this.compRouter.routeToContextUrls('trainings');
        break;
      case 'My Project Allocation':
        this.compRouter.routeToContextUrls('projects');
        break;
      case 'My Compliance':
        this.sharedService.routerObj = 'compliance';
        this.compRouter.routeToContextUrls('profile');
        break;
      case 'My Tenure':
        this.sharedService.routerObj = 'profile';
        this.compRouter.routeToContextUrls('profile');
        break;
      default:
        this.compRouter.routeToContextUrls('');
    }
  }

  getFirstValue(data: any): any {
    const keys = Object.keys(data);
    return keys.length ? data[keys[0]] : null;
  }


  transformDataToMonthRange(data) {
    const monthNames = [
      "January", "February", "March", "April", "May",
      "June", "July", "August", "September", "October",
      "November", "December"
    ];

    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.projectId]) {
        acc[item.projectId] = [];
      }
      acc[item.projectId].push(item.monthYear);
      return acc;
    }, {});

    const result = Object.keys(groupedData).map((projectId) => {
      const months = groupedData[projectId];
      const sortedMonths = months.sort();

      const startYear = sortedMonths[0].split("-")[0];
      const startMonth = parseInt(sortedMonths[0].split("-")[1], 10);
      const endMonth = parseInt(sortedMonths[sortedMonths.length - 1].split("-")[1], 10);

      const monthRange = `${monthNames[startMonth - 1]} to ${monthNames[endMonth - 1]} ${startYear}`;

      return {
        projectId: Number(projectId),
        monthRange,
      };
    });

    return result;
  }

  onEmployeeSelect(user){
    this.selectedUser = {
      id: user.id,
      firstName: user.firstname,
      lastName: user.lastname,
      imagePath: user.imgPath,
      designation: user.desigName,
      phone: user.phone,
      email: user.email,
      dob: user.dob,
      consultantProfileUrl: user.consultantProfileUrl,
      doj: user.doj,
      tenure: user.tenure,
    };

    // setTimeout(() =>
      this.scrollToId(this.chatIndex+'-user');
    // 4000);


    console.log(this.selectedUser);
  }

  scrollToId(divId): void {
    const elementWithId = document.getElementById(divId);
    if (elementWithId) {
      elementWithId.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
