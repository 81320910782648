import { UtilsService } from './../../../../helpers/services/core/utils.service';
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CompRouterService, SharedService } from 'app/helpers/services';
import { ROUTERLINKS } from '../../../../constants/index';

@Component({
  selector: 'app-thor-profile-preview',
  templateUrl: './thor-profile-preview.component.html',
  styleUrls: ['./thor-profile-preview.component.scss']
})
export class ThorProfilePreviewComponent implements OnInit {
  @Input() profile: any;


  constructor(private router: Router, private compRouter: CompRouterService, private utils :UtilsService) { }

  ngOnInit() {
  }

  getRedirectURL(id) {
    if(id){
      this.router.navigate([ROUTERLINKS.USER_PROFILE], { queryParams: { id: this.utils.idEncryption(id) } })
    }else {
      this.compRouter.routeToContextUrls('user');
    }
  }
}
