import { UtilsService } from 'app/helpers/services';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-thor-form',
  templateUrl: './thor-form.component.html',
  styleUrls: ['./thor-form.component.scss']
})
export class ThorFormComponent implements OnInit {
  @Input() data: any;
  actionData: any;
  disabled: boolean = false;
  @Output() messageEvent = new EventEmitter<string>();
  isPickerOpen = false;
  @ViewChildren('formInput') formInputs!: QueryList<ElementRef>;
  cancel: boolean = false;

  constructor(public utils: UtilsService) { }

  ngOnInit() {
    this.actionData = this.utils.getObjectDeepCopy(this.data);

    for (let i = 0; i < this.actionData.length; i++) {
      if (this.actionData[i].type === "time") {
        if (this.actionData[i].format === "HH:MM") {

        } else if (this.actionData[i].format === "MM") {
          this.actionData[i].value = this.convertMinutesToTime(this.actionData[i].value);
        }
      }
    }
  }

  ngAfterViewInit(): void {
    if (this.formInputs && this.formInputs.first) {
      this.formInputs.first.nativeElement.focus();
    }
  }

  cancelAction() {
    this.cancel = true;
  }

  onFormSubmit(form) {
    if (form.form.status === 'INVALID' && !this.cancel) {
      return;
    }

    this.disabled = true;

    let result;
    if (!this.cancel) {
      result = this.utils.getObjectDeepCopy(this.actionData);
      for (let i = 0; i < result.length; i++) {
        if (result[i].type === "time" && result[i].format === "MM") {
          result[i].value = this.convertTimeToMinutes(result[i].value);
        }
      }
    } else {
      result = this.data;
      for (let i = 0; i < result.length; i++) {
        result[i].value = "";
      }
    }
    this.messageEvent.emit(result);
  }

  convertTimeToMinutes(time) {
    const [hours, minutes] = time.split(':').map(Number);
    return (hours * 60) + minutes;
  }

  convertMinutesToTime(minutes: number): string {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;

    // Use the padWithZero function to ensure two-digit formatting
    return `${this.padWithZero(hours)}:${this.padWithZero(remainingMinutes)}`;
  }

  padWithZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  // SOLUTION TO DATE AND TIME INPUT BUG FOR BOOTSTRAP 3.3.7
  toggleDateOrTimePicker(event) {
    if (this.isPickerOpen) {
      event.target.blur();
      this.isPickerOpen = false;
    } else {
      event.target.showPicker();
      this.isPickerOpen = true;
    }
  }
}
