export class Vendor {
    id: number;
    name: string;
    address: string;
    authorizedSignatoryName: string;
    authorizedDesignationId: number;
    authorizedDesignationName: string;
    authorizedSignatoryEmailId: string;
    companyCINNumber: string;
    domainId: number;
    domainName: string;
    vendorTypeId: number;
    vendorTypeName: string;
    buId: number;
    buName : String;
    contactPersonEmailId: string;
    createdDate: string;
    updatedDate: string;
    createdBy: string;
    updatedBy: string;
    active: boolean;
    deleted: boolean;
    userId: number | null;
    orgId: number | null;
    imagePath: string;
    imageUrl: boolean;
    enterpriseType: string;
	companyGstNumber: string;
	companyPanNumber: string;
	companyTanNumber: string;
	bankBeneficiaryName: string;
	bankAccountNumber: string;
	bankIfscCode: string;
	bankAccountType: string;
	bankName: string;
	bankSwiftCode: string;
    emailChecked: boolean;
    // vendorChecked: boo
}
